import "./Form.css";
import InputMask from "react-input-mask";

function Form(props) {
  function handleChange(e) {
    const regex = /['a-z','0-9','-','/','.',':']/g;
    const regex2 = /(http|https):\/\/([\w/.!&#@-]*).([a-z]{2,})([\w/.!&#@-]*)/gi;
    if (regex.test(e.target.value) || regex2.test(e.target.value)) {
      e.target.value = "";
      alert("Введены запрещенные символы. Используйте только русские буквы.");
    }
  }
  return (
    <div className={props.class}>
      {props.children}
      <form
        className="form"
        action="../../post/form1.php"
        method="POST"
        encType="multipart/form-data"
      >
        <input
          onChange={handleChange}
          className={
            props.class === "sertificate__form"
              ? "form__input form__input_green form__name"
              : "form__input form__name"
          }
          name="name"
          type="text"
          placeholder="Как вас зовут?"
          autoComplete="off"
          maxLength="30"
          required
        />
        {props.class === "form__subcribe" ? (
          <input
            className="form__input form__email"
            name="email"
            type="email"
            placeholder="Ваш E-mail"
            required
          />
        ) : (
          <>
            <input
              className={
                props.class === "sertificate__form"
                  ? "form__input form__input_green form__tel"
                  : "form__input form__tel"
              }
              name="tel"
              type="tel"
              placeholder="Телефон"
              autoComplete="off"
              required
            />
            <input
              className={
                props.class === "sertificate__form"
                  ? "form__input form__input_green form__number"
                  : "form__input form__number"
              }
              name="number"
              type="number"
              placeholder="Количество мест"
              min="1"
              required
            />
          </>
        )}

        <button
          className={
            props.class === "sertificate__form"
              ? "form__button form__button_green"
              : "form__button"
          }
          type="submit"
          value="Забронировать"
        >
          {props.class === "form__subcribe" ? `Подписаться` : `Забронировать`}
        </button>
      </form>
    </div>
  );
}

export default Form;
