import Form from "../Form/Form";
import "./Popup.css";

function Popup(props) {
  return (
    <section
      onClick={(e) => e.currentTarget === e.target && props.onClose()}
      className={
        props.isFormPopoupOpen || props.isImagePopoupOpen
          ? " popup popup_on"
          : "popup"
      }
    >
      <Form
        column={true}
        class={
          props.isFormPopoupOpen
            ? "popup__container popup__form popup__container_active"
            : "popup__container popup__form"
        }
      >
        <button
          className="popup__close popup__close_form"
          type="button"
          onClick={props.onClose}
        ></button>
        <h2 className="form__title">
          Оставьте номер телефона и мы свяжемся с вами в течение 5 минут
        </h2>
      </Form>
    </section>
  );
}

export default Popup;
