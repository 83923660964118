import logo from "../../images/logo.png";
import logomini from "../../images/logomini.png";
import React from "react";
import whatsapp from "../../images/whatsapp.svg";
import telegram from "../../images/telegram.svg";
import instagram from "../../images/instagram.svg";
import "./Header.css";

function Header() {
  const [isOpen, setOpen] = React.useState(false);
  function handlePopupOpen() {
    setOpen(!isOpen);
  }
  return (
    <header className="header">
      <div className="header__container">
        <div className="header__box">
          <img className="header__logo" src={logo} alt="Зеленый Мыс" />
          <p className="header__text">
            База отдыха со всеми удобствами в Красноармейском районе
          </p>
        </div>
        <div className="header__box">
          <p className="header__text-messenger">
            Связаться с нами через мессенджеры
          </p>
          <a
            href="whatsapp://send?phone=%2b79375524147"
            className="header__link"
          >
            <img className="header__messenger" src={whatsapp} alt="WhatsApp" />
          </a>
          <a href="tg://resolve?domain=" className="header__link">
            <img className="header__messenger" src={telegram} alt="Telegram" />
          </a>
          <a href="https://instagram.com/zelem_volga?utm_medium=copy_link" className="contacts__link">
            <img
              className="header__messenger"
              src={instagram}
              alt="Instagram"
            />
          </a>
        </div>
        <div className="header__tel-box">
          <a href="tel:+78442506156" className="header__tel">
            +7 (8442) 506-156
          </a>
          <p className="header__text-tel">Ежедневно с 10:00 до 21:00</p>
        </div>
      </div>
      <nav className="header__nav">
        <a className="nav__link" href="#foryou">
          Наши предложения
        </a>
        <a className="nav__link" href="#service">
          Набор услуг
        </a>
        <a className="nav__link" href="#price">
          Наши цены
        </a>
        <a className="nav__link" href="#sale">
          Акции и скидки
        </a>
        <a className="nav__link" href="#sertificate">
          Подарочный сертификат
        </a>
        <a className="nav__link" href="#contacts">
          Контакты
        </a>
      </nav>
      <div className="header__container_mob">
        <nav
          className={
            isOpen ? "header__nav_mob header__nav_active" : "header__nav_mob"
          }
        >
          <a className="nav__link" href="#foryou">
            Наши предложения
          </a>
          <a className="nav__link" href="#service">
            Набор услуг
          </a>
          <a className="nav__link" href="#price">
            Наши цены
          </a>
          <a className="nav__link" href="#sale">
            Акции и скидки
          </a>
          <a className="nav__link" href="#sertificate">
            Подарочный сертификат
          </a>
          <a className="nav__link" href="#contacts">
            Контакты
          </a>
        </nav>
        <div className="header__box">
          <button
            className="header__button-nav"
            type="button"
            onClick={handlePopupOpen}
          ></button>
          <img className="header__logo" src={logomini} alt="Зеленый Мыс" />
        </div>
        <div className="header__tel-box">
          <a href="tel:+78442506156" className="header__tel">
            +7 (8442) 506-156
          </a>
          <p className="header__text-tel">Ежедневно с 10:00 до 21:00</p>
          <div className="header__box">
            <a
              href="whatsapp://send?phone=%2b79375524147"
              className="header__link"
            >
              <img
                className="header__messenger"
                src={whatsapp}
                alt="WhatsApp"
              />
            </a>
            <a href="" className="header__link">
              <img
                className="header__messenger"
                src={telegram}
                alt="Telegram"
              />
            </a>
            <a href="https://instagram.com/zelem_volga?utm_medium=copy_link" className="contacts__link">
            <img
              className="header__messenger"
              src={instagram}
              alt="Instagram"
            />
          </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
