import React, { Suspense } from "react";
import "./App.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Popup from "../Popup/Popup";

const Main = React.lazy(() => import("../Main/Main"));
const ForYou = React.lazy(() => import("../ForYou/ForYou"));
const Service = React.lazy(() => import("../Service/Service"));
const Price = React.lazy(() => import("../Price/Price"));
const Sale = React.lazy(() => import("../Sale/Sale"));
const Sertificate = React.lazy(() => import("../Sertificate/Sertificate"));
const Contacts = React.lazy(() => import("../Contacts/Contacts"));

function App() {
  const [isFormPopoupOpen, setFormPopupOpen] = React.useState(false);
  function closeAllPopups() {
    setFormPopupOpen(false);
  }
  function handleFormPopupOpen(e) {
    closeAllPopups(e);
    setFormPopupOpen(true);
  }
  return (
    <div className="page">
      <Header />
      <Suspense fallback={<div></div>}>
        <Main onClickForm={handleFormPopupOpen} />
        <ForYou />
        <Service />
        <Price />
        <Sale />
        <Sertificate />
        <Contacts />
      </Suspense>
      <Footer />
      <Popup onClose={closeAllPopups} isFormPopoupOpen={isFormPopoupOpen} />
    </div>
  );
}

export default App;
