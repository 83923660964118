import "./Footer.css";
import logo from "../../images/logo1.png";
import logomini from "../../images/logomini1.png";
import whatsapp from "../../images/whatsapp.svg";
import telegram from "../../images/telegram.svg";
import instagram from "../../images/instagram.svg";
import React from "react";

function Footer() {
  const [isOpen, setOpen] = React.useState(false);
  function handlePopupOpen() {
    setOpen(!isOpen);
  }
  return (
    <section className="footer__bg">
      <footer className="footer">
        <nav className="footer__nav">
          <a className="nav__link_footer" href="#foryou">
            Наши предложения
          </a>
          <a className="nav__link_footer" href="#service">
            Набор услуг
          </a>
          <a className="nav__link_footer" href="#price">
            Наши цены
          </a>
          <a className="nav__link_footer" href="#sale">
            Акции и скидки
          </a>
          <a className="nav__link_footer" href="#sertificate">
            Подарочный сертификат
          </a>
          <a className="nav__link_footer" href="#contacts">
            Контакты
          </a>
        </nav>
        <div className="footer__container">
          <div className="footer__box">
            <img className="footer__logo" src={logo} alt="Зеленый Мыс" />
            <p className="footer__text">
              База отдыха со всеми удобствами в Красноармейском районе
            </p>
          </div>
          <div className="footer__box">
            <p className="footer__text-messenger">
              Связаться с нами через мессенджеры
            </p>
            <a
              href="whatsapp://send?phone=%2b79375524147"
              className="footer__link"
            >
              <img
                className="footer__messenger"
                src={whatsapp}
                alt="WhatsApp"
              />
            </a>
            <a href="tg://resolve?domain=" className="footer__link">
              <img
                className="footer__messenger"
                src={telegram}
                alt="Telegram"
              />
            </a>
            <a href="https://instagram.com/zelem_volga?utm_medium=copy_link" className="contacts__link">
            <img
              className="header__messenger"
              src={instagram}
              alt="Instagram"
            />
          </a>
          </div>
          <div className="footer__tel-box">
            <a href="tel:+78442506156" className="footer__tel">
              +7 (8442) 506-156
            </a>
            <p className="footer__text-tel">Ежедневно с 10:00 до 21:00</p>
          </div>
        </div>

        <div className="footer__container_mob">
          <nav
            className={
              isOpen ? "footer__nav_mob footer__nav_active" : "footer__nav_mob"
            }
          >
            <a className="nav__link_footer" href="#foryou">
              Наши предложения
            </a>
            <a className="nav__link_footer" href="#service">
              Набор услуг
            </a>
            <a className="nav__link_footer" href="#price">
              Наши цены
            </a>
            <a className="nav__link_footer" href="#sale">
              Акции и скидки
            </a>
            <a className="nav__link_footer" href="#sertificate">
              Подарочный сертификат
            </a>
            <a className="nav__link_footer" href="#contacts">
              Контакты
            </a>
          </nav>
          <div className="footer__box">
            <button
              className="footer__button-nav"
              type="button"
              onClick={handlePopupOpen}
            ></button>
            <img className="footer__logo" src={logomini} alt="Зеленый Мыс" />
          </div>
          <div className="footer__tel-box">
            <a href="tel:+78442506156" className="footer__tel">
              +7 (8442) 506-156
            </a>
            <p className="footer__text-tel">Ежедневно с 10:00 до 21:00</p>
            <div className="footer__box">
              <a
                href="whatsapp://send?phone=%2b79375524147"
                className="footer__link"
              >
                <img
                  className="footer__messenger"
                  src={whatsapp}
                  alt="WhatsApp"
                />
              </a>
              <a href="tg://resolve?domain=" className="footer__link">
                <img
                  className="footer__messenger"
                  src={telegram}
                  alt="Telegram"
                />
              </a>
              <a href="https://instagram.com/zelem_volga?utm_medium=copy_link" className="contacts__link">
            <img
              className="header__messenger"
              src={instagram}
              alt="Instagram"
            />
          </a>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
}

export default Footer;
